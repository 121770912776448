html,
body {
  background-color: transparent;
}

.App {
  padding: 20px;
  background-color: transparent;
}

.reactions {
  /* background-color: red; */
  position: absolute;
  right: 0;
  bottom: 0;
}

.c-hearts {
  width: 200px;
  height: 400px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-50%, -20%);
  position: relative;
}

.c-hearts__particle {
  width: 30px;
  height: 30px;
  opacity: 1;
  position: absolute;
  bottom: 0;
  animation-fill-mode: forwards;
}

@keyframes flowOne {
  0% {
    opacity: 0;
    bottom: 0;
    left: 35%;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    left: 45%;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    bottom: 80%;
  }
  100% {
    opacity: 0;
    bottom: 100%;
    left: 68%;
  }
}

@keyframes flowTwo {
  0% {
    opacity: 0;
    bottom: 0;
    left: 45%;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    left: 61%;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    bottom: 60%;
  }
  100% {
    opacity: 0;
    bottom: 80%;
    left: 45%;
  }
}

@keyframes flowThree {
  0% {
    opacity: 0;
    bottom: 0;
    left: 45%;
  }
  40% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    left: 25%;
  }
  60% {
    opacity: 0.2;
  }
  80% {
    bottom: 70%;
  }
  100% {
    opacity: 0;
    bottom: 90%;
    left: 45%;
  }
}
